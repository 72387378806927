// @ts-nocheck

import React from 'react';
import {Col, Row} from 'reactstrap';
import {DynamicImageTable} from "../components/ImageTextTable";

const Page: React.FC = () => {
  return (
    <div>
      <h2>Kuscheltierli superweich - handgestrickt</h2>
      <Row>
        <Col md={1}/>
        <Col md={10}>
          <span>Die Plüschtierli sind aus Chenille-Wolle</span>
          <br/>
          <span>Sie können gewaschen werden und sind auch für Allergiker geeignet.</span>
          <br/>
          <span>Ich mache dir gerne ein Tier in deinen Lieblingsfarben. Spezialwünsche sind erwünscht</span>
          <br/>
        </Col>
      </Row>
      <br/>
      <DynamicImageTable />
    </div>
  );
};

export default Page;
